



















import {
  Component, Inject, ProvideReactive, Vue,
} from 'vue-property-decorator';
import UserMainCard from '@/views/profile/components/UserMainCard.vue';
import { ApiFacade } from '@/services/ApiFacade';
import { User } from '@/models/entities/User';
import { AppStore } from '@/store/types/AppStore';

@Component({
  components: {
    UserMainCard,
  },
})
export default class ProfileView extends Vue {
  @Inject() readonly apiFacade!: ApiFacade;
  @Inject() appStore!: AppStore;
  @ProvideReactive() user: User | null = null;

  async mounted() {
    const id = parseInt(this.$route.params.id, 10);
    if (!id) {
      return;
    }
    this.user = await this.apiFacade.fetchUser(id);
  }
}
